import React from 'react';

const IconUVA = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="120.000000pt"
    height="120.000000pt"
    viewBox="0 0 120.000000 120.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
      fill="#ffffff"
      stroke="none"
    >
      <path
        d="M177 1186 c-66 -24 -111 -61 -142 -117 l-30 -54 0 -415 0 -415 30
-54 c32 -58 90 -103 155 -121 46 -13 774 -13 820 0 65 18 123 63 155 121 l30
54 0 415 0 415 -30 54 c-32 58 -90 103 -155 121 -57 16 -787 12 -833 -4z m113
-389 c0 -118 12 -147 60 -147 48 0 60 29 60 147 l0 103 30 0 30 0 0 -109 c0
-60 -4 -121 -10 -135 -26 -70 -183 -75 -220 -7 -5 11 -10 72 -10 135 l0 116
30 0 30 0 0 -103z m311 -3 l34 -106 35 106 34 106 34 0 c23 0 33 -4 30 -12 -3
-7 -27 -75 -54 -151 l-48 -138 -35 3 -34 3 -44 130 c-24 72 -46 138 -49 148
-4 14 1 17 28 17 l34 0 35 -106z m347 10 c19 -13 22 -24 22 -84 0 -38 4 -80
10 -94 9 -24 8 -26 -20 -26 -16 0 -32 5 -35 10 -4 7 -12 7 -25 0 -31 -16 -78
-12 -100 10 -41 41 -18 86 56 105 37 9 49 17 49 31 0 25 -35 31 -51 9 -9 -12
-20 -16 -38 -11 -33 8 -33 19 0 45 32 25 100 28 132 5z m-738 -519 c0 -35 0
-35 45 -35 45 0 45 0 45 35 0 19 5 35 10 35 6 0 10 -35 10 -85 0 -50 -4 -85
-10 -85 -5 0 -10 18 -10 40 l0 40 -45 0 -45 0 0 -40 c0 -22 -4 -40 -10 -40 -6
0 -10 35 -10 85 0 50 4 85 10 85 6 0 10 -16 10 -35z m460 16 c0 -11 6 -22 13
-25 10 -5 10 -7 0 -12 -15 -7 -18 -94 -3 -94 6 0 10 -5 10 -11 0 -6 -10 -9
-22 -7 -20 3 -23 10 -26 53 -2 27 -9 53 -15 57 -8 6 -9 10 -1 16 6 4 13 15 17
25 9 23 27 22 27 -2z m60 9 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m-350 -80 c0 -36 4 -52 15 -56 26 -10 45 16 45 62 0
24 5 44 10 44 6 0 10 -28 10 -63 l0 -64 -37 -1 c-20 -1 -42 3 -50 9 -15 13
-18 119 -3 119 6 0 10 -23 10 -50z m203 44 c14 -4 17 -17 17 -65 0 -33 -4 -59
-10 -59 -5 0 -10 23 -10 50 0 36 -4 52 -15 56 -26 10 -45 -16 -45 -62 0 -24
-4 -44 -10 -44 -6 0 -10 28 -10 63 l0 64 33 1 c17 1 40 -1 50 -4z m147 -59 c0
-51 -3 -65 -15 -65 -12 0 -15 14 -15 65 0 51 3 65 15 65 12 0 15 -14 15 -65z
m113 59 c14 -4 17 -17 17 -65 0 -33 -4 -59 -10 -59 -5 0 -10 23 -10 50 0 36
-4 52 -15 56 -26 10 -45 -16 -45 -62 0 -24 -4 -44 -10 -44 -6 0 -10 28 -10 63
l0 64 33 1 c17 1 40 -1 50 -4z m167 -63 c0 -79 -17 -111 -60 -111 -28 0 -67
27 -55 38 3 3 16 0 29 -8 28 -14 56 -8 56 13 0 9 -9 12 -29 9 -50 -7 -79 58
-46 105 12 18 25 22 60 21 l45 -1 0 -66z"
      />
      <path
        d="M885 692 c-29 -6 -45 -21 -38 -38 7 -18 37 -18 53 1 17 20 9 41 -15
37z"
      />
      <path d="M921 238 c-12 -33 2 -63 30 -63 20 0 25 6 27 34 5 50 -41 73 -57 29z" />
    </g>
  </svg>
);

export default IconUVA;
