import React, { useState, useEffect, useRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ModalImage from '../ImageModal/ModalImage';
import Modal from '../ImageModal/Modal';

const StyledCertificatesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .archive-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .projects-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;
    width: 100%;
    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    margin: 80px auto 0;
  }
`;

const StyledCertificates = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .project-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .project-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
    overflow: auto;
  }

  .project-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .project-links {
      display: flex;
      align-items: center;
      margin-right: -10px;
      color: var(--light-slate);

      a {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 5px 7px;

        &.external {
          svg {
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .project-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .project-description {
    color: var(--light-slate);
    font-size: 17px;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tag-list {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const Certificates = () => {
  const [modalImage, setModalImage] = useState();
  const data = useStaticQuery(graphql`
    query {
      certificates: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/certificates/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              course_link
              tags
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `);

  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealCertificates = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealCertificates.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const certificates = data.certificates.edges.filter(({ node }) => node);
  const projectsToShow = certificates;

  const projectInner = node => {
    const { frontmatter, html } = node;
    const { title, course_link, tags, cover } = frontmatter;
    const image = getImage(cover);
    return (
      <div className="project-inner" id="certificates">
        <header>
          <div className="project-top">
            <div className="folder">
              <div
                className="project-image"
                onClick={() => {
                  setModalImage(image);
                }}>
                <a className="cursor-zoom">
                  <GatsbyImage image={image} alt={title} className="img" />
                </a>
              </div>
            </div>
          </div>

          <h3 className="project-title">
            {course_link ? (
              <a href={course_link} target="_blank" rel="noreferrer">
                {title}
              </a>
            ) : (
              <a target="_blank" rel="noreferrer">
                {title}
              </a>
            )}
          </h3>
        </header>

        <footer>
          {tags && (
            <ul className="project-tag-list">
              {tags.map((tag, i) => (
                <li key={i}>{tag}</li>
              ))}
            </ul>
          )}
        </footer>
      </div>
    );
  };

  return (
    <StyledCertificatesSection>
      <h2 className="numbered-heading" ref={revealTitle}>
        Certificates
      </h2>
      {modalImage && (
        <Modal
          open
          setOpen={event => {
            if (event === false) {
              setModalImage(null);
            }
          }}
          image={modalImage}
          alt={'Certificate'}
          rounded
        />
      )}

      <ul className="projects-grid">
        {prefersReducedMotion ? (
          <>
            {projectsToShow &&
              projectsToShow.map(({ node }, i) => (
                <StyledCertificates key={i}>{projectInner(node)}</StyledCertificates>
              ))}
          </>
        ) : (
          <TransitionGroup component={null}>
            {projectsToShow &&
              projectsToShow.map(({ node }, i) => (
                <CSSTransition key={i} classNames="fadeup" timeout={300} exit={false}>
                  <StyledCertificates
                    key={i}
                    ref={el => (revealCertificates.current[i] = el)}
                    style={{
                      transitionDelay: `0ms`,
                    }}>
                    {projectInner(node)}
                  </StyledCertificates>
                </CSSTransition>
              ))}
          </TransitionGroup>
        )}
      </ul>
    </StyledCertificatesSection>
  );
};

export default Certificates;
