import React from 'react';

const Medium = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256">
        <title>Medium</title>
        
        <rect x="0" y="0" width="256" height="256" fill="none" stroke="none" /><path fill="currentColor" d="M136 128c0 37.5-28.7 68-64 68S8 165.5 8 128s28.7-68 64-68s64 30.5 64 68Zm104-64a8 8 0 0 0-8 8v112a8 8 0 0 0 16 0V72a8 8 0 0 0-8-8Zm-56 0c-5.7 0-16.4 2.8-24.3 21.3c-5 11.5-7.7 26.7-7.7 42.7s2.7 31.2 7.7 42.7c7.9 18.5 18.6 21.3 24.3 21.3s16.4-2.8 24.3-21.3c5-11.5 7.7-26.7 7.7-42.7s-2.7-31.2-7.7-42.7C200.4 66.8 189.7 64 184 64Z"/></svg>
  );

export default Medium;
