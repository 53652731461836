import React, { useEffect } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import GatsbyImage from './ImageGatsby';
import styled from 'styled-components';
import Icon from '../icons/icon';

// Styled components
const Overlay = styled.div`
  position: fixed;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => handleHexToRgba(props.colors.background, 0.7)};
  animation: 0.2s fade;
`;
const ImgWrapper = styled.div`
  display: flex;
  margin: 0 10px;

  @media screen and (min-width: 768px) {
    max-width: 60%;
    max-height: 80%;
    margin-top: 60px;
    padding: 0;
  }
`;
const ContentWrapper = styled.div`
  box-shadow: 3px 5px 7px ${() => handleHexToRgba('#333', 0.4)};
  position: relative;
  margin: 0 auto;
`;
const CloseIcon = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  z-index: 20;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  color: ${props => props.colors.primary};

  svg {
    color: ${props => props.colors.primary};
    font-size: 1.8rem;

    :hover {
      color: ${props => props.colors.secondary};
    }
  }
`;
const handleBlockScroll = condition => {
  condition
    ? (document.getElementsByTagName('body')[0].style.overflow = 'hidden')
    : (document.getElementsByTagName('body')[0].style.overflow = 'visible');
};
const handleHexToRgba = (hex = '', opacity = 1) => {
  if (hex) {
    let result = hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return `rgba(${result[0]},${result[1]},${result[2]}, ${opacity} )`;
  }
  return '';
};

// const useFilterImage = (src, grayscale): object => {
//   //Types
//   type ImageData = {
//     gatsbyImageData: {
//       images: {
//         fallback: {
//           src;
//         };
//       };
//     };
//   };

//   let image: ImageData;
//   let arr: any;

//   const query = useStaticQuery(graphql`
//     query {
//       allImageSharp {
//         nodes {
//           gatsbyImageData(layout: CONSTRAINED)
//         }
//       }
//       grayscale: allImageSharp {
//         nodes {
//           gatsbyImageData(layout: CONSTRAINED, transformOptions: { grayscale: true })
//         }
//       }
//     }
//   `);

//   if (grayscale) {
//     arr = query.grayscale.nodes;
//     image = arr.find(item => item.gatsbyImageData.images.fallback.src.includes(src));

//     return image !== undefined && image.gatsbyImageData;
//   } else {
//     arr = query.allImageSharp.nodes;
//     image = arr.find(item => item.gatsbyImageData.images.fallback.src.includes(src));

//     return image !== undefined && image.gatsbyImageData;
//   }
// };

function useOutsideClick(ref, action) {
  ref === undefined && console.error('ref is not reachable | useOutsideClick hook');

  useEffect(() => {
    function handleClickOutside2(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    document.addEventListener('mousedown', () => handleClickOutside2);
    return () => document.removeEventListener('mousedown', () => handleClickOutside2);
  }, [ref]);
}

const Modal = ({
  image,
  alt,
  setOpen,
  open,
  rounded,
  colors = { background: '#333', primary: '#000', secondary: '#222' },
}) => {
  let wrapperRef = React.useRef('modal');
  useOutsideClick(wrapperRef, handleClose);

  // Block scroll on open
  React.useEffect(() => {
    open && handleBlockScroll(open);
  }, [open]);
  //

  function handleClose() {
    setOpen(false);

    // Unblock scroll on close
    handleBlockScroll(false);
  }

  return (
    <Overlay
      onClick={handleClose}
      colors={{
        background: colors.background,
        primary: colors.primary,
        secondary: colors.secondary,
      }}>
      <ImgWrapper id="modal" ref={wrapperRef}>
        <ContentWrapper duration={300}>
          <CloseIcon
            onClick={handleClose}
            colors={{
              background: colors.background,
              primary: colors.primary,
              secondary: colors.secondary,
            }}>
            <Icon name="Close" />
            {/* <RiCloseFill /> */}
          </CloseIcon>
          <GatsbyImage rounded={rounded} image={image} alt={alt} forceheight />
        </ContentWrapper>
      </ImgWrapper>
    </Overlay>
  );
};

export default Modal;
